import axios from "axios";
import config from "@config";
import graphql from "@graphql";
import { getCurrentLanguage } from "@storefront/core/i18n";
import { Logger } from "@storefront/core/lib/logger";
import store from "@/store";

const state = () => ({
  wishlistId: null,
  wishItems: [],
});

const actions = {
  async loadWishlist({ getters, commit, dispatch }) {
    if (getters["getWishlistId"] == null) {
      const wishToken = localStorage.getItem("wishListId");
      if (wishToken !== null) {
        commit("setWishlistId", wishToken);
      }
    }

    if (getters["getWishlistId"] != null) {
      const wishlist = await dispatch("getWishlist");

      if (wishlist != false) {
        const wishItems = wishlist.items_v2.items;
        commit("setWishItems", wishItems);
      } else {
        commit("setWishlistId", null);
        commit("setWishItems", []);
      }
    } else {
      commit("setWishItems", []);
    }
  },
  async loadLoginWishlist({ getters, dispatch, commit }) {
    const newWishToken = await dispatch("getCustomerWishToken");
    const oldWishToken = getters["getWishlistId"];
    if (oldWishToken != null) {
      await dispatch("mergeWishlist", {
        oldToken: oldWishToken,
        newToken: newWishToken,
      });
    }
    commit("setWishlistId", newWishToken);
    dispatch("loadWishlist");
  },
  async addProductToWishlist(
    { getters, commit, dispatch },
    { sku, parentSku, entered_options }
  ) {
    if (getters["getWishlistId"] == null && localStorage.getItem('wishListId') == null) {
      const wishToken = await dispatch("createGuestWishToken");
      commit("setWishlistId", wishToken);
      localStorage.setItem('wishListId', wishToken);
    }

    const retval = await dispatch("addToWishlist", { sku, parentSku, entered_options });
    if (retval == true) {
      dispatch("loadWishlist");
    }
    return retval;
  },
  async removeProductFromWishlist({ getters, dispatch }, { sku }) {
    const id = getters["getProductInWishlistId"](sku);
    const retval = await dispatch("removeFromWishlist", id);
    if (retval == true) {
      dispatch("loadWishlist");
    }
    return retval;
  },
  async createGuestWishToken() {
    const lang = getCurrentLanguage();
    const storelang = config.languages[lang];
    const storeview = storelang["storeview"];

    const headers = {
      "Content-Type": "application/json",
      //Authorization: "Bearer " + config.shop.accessToken,
      Store: storeview,
    };

    const query = " mutation {createEmptyWishlist}";
    const retval = await axios({
      url: config.shop.graphQLURL,
      method: "POST",
      headers: headers,
      data: { query: query },
    }).catch((e) => {
      Logger.error("createGuestWishToken", "store wishlist", e)();
      throw e;
    });
    if (retval.data.data.createEmptyWishlist != null) {
      return retval.data.data.createEmptyWishlist;
    } else {
      return false;
    }
  },
  async getCustomerWishToken() {
    const lang = getCurrentLanguage();
    const storelang = config.languages[lang];
    const storeview = storelang["storeview"];

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + store.getters["user/getUserToken"],
      Store: storeview,
    };

    const query = "{ customer { wishlist{ id } } }";

    const retval = await axios({
      url: config.shop.graphQLURL + "?query=" + encodeURI(query),
      method: "GET",
      headers: headers,
    }).catch((e) => {
      Logger.error("getCustomerWishToken", "store wishlist", e)();
      throw e;
    });
    if (retval && retval.data && retval.data.data && retval.data.data.customer
      && retval.data.data.customer.wishlist.id != null) {
      return retval.data.data.customer.wishlist.id;
    } else {
      return false;
    }
  },
  async getWishlist({ getters }) {
    const lang = getCurrentLanguage();
    const storelang = config.languages[lang];
    const storeview = storelang["storeview"];
    const wishlistId = getters['getWishlistId'] ?? localStorage.getItem('wishListId');

    let headers = {};
    if (store.getters["user/getIsLoggedIn"] != false) {
      headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + store.getters["user/getUserToken"],
        Store: storeview,
      };
    } else {
      headers = {
        "Content-Type": "application/json",
        // Authorization: "Bearer " + config.shop.consumerKey,
        Store: storeview,
      };
    }

    const query =
      ' { wishlist(wishlist_id: "' +
      wishlistId +
      '") ' +
      graphql.queryFields.wishlist +
      " }";

    const retval = await axios({
      url: config.shop.graphQLURL + "?query=" + encodeURI(query),
      method: "GET",
      headers: headers,
    }).catch((e) => {
      Logger.error("getWishlist", "store wishlist", e)();
      throw e;
    });

    if (retval && retval.data && retval.data.data && retval.data.data.wishlist != null) {
      return retval.data.data.wishlist;
    } else {
      return false;
    }
  },
  async addToWishlist({ getters, commit }, { sku, parentSku, selected_options }) {
    const lang = getCurrentLanguage();
    const storelang = config.languages[lang];
    const storeview = storelang["storeview"];
    const wishlistId = getters['getWishlistId'] ?? localStorage.getItem('wishListId');
    let headers = {};
    if (store.getters["user/getIsLoggedIn"] != false) {
      headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + store.getters["user/getUserToken"],
        Store: storeview,
      };
    } else {
      headers = {
        "Content-Type": "application/json",
        // Authorization: "Bearer " + config.shop.consumerKey,
        Store: storeview,
      };
    }

    let query = "mutation { addProductsToWishlist( ";
    query = query + 'wishlistId: "' + wishlistId + '"';
    query = query + ' wishlistItems: [{ sku: "' + sku + '"';
    if (parentSku != null) {
      query = query + ' parent_sku: "' + parentSku + '"';
    }
    if (selected_options != null) {
      query = query + ' selected_options: ' + JSON.stringify(selected_options)
    }

    query = query + " quantity: 1}]";

    query =
      query +
      " ) {user_errors { code message } wishlist { id items_count items { id qty product { name sku id price_range { minimum_price { regular_price { currency value } } maximum_price { regular_price { currency value } } } } } } } }";
    const retval = await axios({
      url: config.shop.graphQLURL,
      method: "POST",
      headers: headers,
      data: { query: query },
    }).catch((e) => {
      Logger.error("addToWishlist", "store wishlist", e)();
      throw e;
    });
    if (retval && retval.data && retval.data.data && retval.data.data.addProductsToWishlist != null) {
      if (retval.data.data.addProductsToWishlist.user_errors.length > 0) {
        const msg = {
          type: "danger",
          title: "Wensenlijst",
          text: retval.data.data.addProductsToWishlist.user_errors[0].message,
        };
        store.dispatch("messages/sendMessage", { message: msg });
      }
      commit('setWishlistId', retval.data.data.addProductsToWishlist.wishlist.id);
      commit('setWishItems', retval.data.data.addProductsToWishlist.wishlist.items);
      return true;
    } else {
      return false;
    }
  },
  async removeFromWishlist({ getters, commit }, id) {
    const lang = getCurrentLanguage();
    const storelang = config.languages[lang];
    const storeview = storelang["storeview"];

    let headers = {};
    if (store.getters["user/getIsLoggedIn"] != false) {
      headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + store.getters["user/getUserToken"],
        Store: storeview,
      };
    } else {
      headers = {
        "Content-Type": "application/json",
        // Authorization: "Bearer " + config.shop.consumerKey,
        Store: storeview,
      };
    }

    let query = "mutation { removeProductsFromWishlist( ";
    query = query + 'wishlistId: "' + getters["getWishlistId"] + '"';
    query = query + " wishlistItemsIds: [" + id + "]";

    query =
      query +
      " ) {user_errors { code message } wishlist { id items_count items { id qty product { name sku id price_range { minimum_price { regular_price { currency value } } maximum_price { regular_price { currency value } } } } } } } }";
    const retval = await axios({
      url: config.shop.graphQLURL,
      method: "POST",
      headers: headers,
      data: { query: query },
    }).catch((e) => {
      Logger.error("removeFromWishlist", "store wishlist", e)();
      throw e;
    });
    if (retval.data.data.removeProductsFromWishlist != null) {
      commit('setWishlistId', retval.data.data.removeProductsFromWishlist.wishlist.id);
      commit('setWishItems', retval.data.data.removeProductsFromWishlist.wishlist.items);
      return true;
    } else {
      return false;
    }
  },
  async mergeWishlist(_, { oldToken, newToken }) {
    const lang = getCurrentLanguage();
    const storelang = config.languages[lang];
    const storeview = storelang["storeview"];

    let headers = {};
    if (store.getters["user/getIsLoggedIn"] != false) {
      headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + store.getters["user/getUserToken"],
        Store: storeview,
      };
    } else {
      headers = {
        "Content-Type": "application/json",
        // Authorization: "Bearer " + config.shop.consumerKey,
        Store: storeview,
      };
    }

    const query =
      'mutation { mergeWishlists(source_wishlist_id: "' +
      oldToken +
      '" destination_wishlist_id: "' +
      newToken +
      '") { id items_v2 { items { id quantity product { sku  name } } } } } ';

    const retval = await axios({
      url: config.shop.graphQLURL,
      method: "POST",
      headers: headers,
      data: { query: query },
    }).catch((e) => {
      Logger.error("mergeWishlist", "store wishlist", e)();
      throw e;
    });
    if (retval.data.data.mergeWishlists != null) {
      return retval.data.data.mergeWishlists;
    } else {
      return false;
    }
  },
};

const mutations = {
  setWishlistId: (state, payload) => {
    state.wishlistId = payload;
    if (payload != null) {
      localStorage.setItem("wishlistToken", payload);
    } else {
      localStorage.removeItem("wishlistToken");
    }
  },
  setWishItems(state, payload) {
    state.wishItems = payload;
  },
};

const getters = {
  getWishlistId: (state) => state.wishlistId,
  getWishItems: (state) => state.wishItems,
  getWishQuantity: (state) => state.wishItems.length,
  isProductInWishlist: (state) => (sku) => {
    if (state.wishItems.length > 0) {
      return state.wishItems.some((item) => item.product.sku === sku);
    } else {
      return false;
    }
  },
  getProductInWishlistId: (state) => (sku) => {
    if (state.wishItems.length > 0) {
      const obj = state.wishItems.find((o) => {
        if (o.product.sku == sku) {
          return true;
        }
      });
      if (obj == null) {
        return null;
      } else {
        return obj.id;
      }
    } else {
      return null;
    }
  },
};

// export this module.
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
