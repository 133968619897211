import { Logger } from "@storefront/warmlogger.mjs";
import { getProductBySku } from "@storefront/core/data-resolver/products";

export default {
  namespaced: true,

  state() {
    return {
      compare: [],
    };
  },

  actions: {
    loadCompare({ commit }) {
      const products = localStorage.getItem("compareProducts");
      if (products) {
        commit("setProductToCompare", JSON.parse(products));
      }

      // const compareExpiration = localStorage.getItem("compareExpiration");
      // if (compareExpiration - Date.now() > 0) {
      //   const products = localStorage.getItem("compareProducts");
      //   if (products) {
      //     commit("setProductToCompare", JSON.parse(products));
      //   }
      // } else {
      //   localStorage.removeItem("compareProducts");
      //   localStorage.removeItem("compareExpiration");
      // }
    },
    async addCompareProduct({ commit, getters }, { sku }) {
      const products = await getProductBySku(sku).catch((e) => {
        Logger.error("addCompareProduct", "product store actions load", e)();
        throw e;
      });
      const product = products.items[0];

      const newProducts = getters["getProductCompare"];
      newProducts.push(product);

      commit("setProductToCompare", newProducts);
    },
    removeCompareProduct({ commit, getters }, { sku }) {
      const products = getters["getProductCompare"];
      products.forEach((prod, index) => {
        if (prod.sku == sku) {
          products.splice(index, 1);
        }
      });
      commit("setProductToCompare", products);
    },
    resetCompareProducts({ commit }) {
      commit("setProductToCompare", []);
      localStorage.removeItem("compareProducts");
    },
  },

  mutations: {
    setProductToCompare(state, products) {
      state.compare = products;
      localStorage.setItem("compareProducts", JSON.stringify(products));
      // const compareExpiration = Date.now() + 1 * (24 * 60 * 60 * 1000); // 2 Days
      // localStorage.setItem("compareExpiration", compareExpiration);
    },
  },

  getters: {
    getProductCompare: (state) => state.compare,
  },
};
