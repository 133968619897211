export default {
    namespaced: true,

    state() {
        return {
            mobileCategoryFilterShow: false
        }
    },

    getters: {
        getMobileCategoryFilterShow(state) {
            return state.mobileCategoryFilterShow
        }
    },

    mutations: {
        setMobileCategoryFilterShow(state, isShow) {
            state.mobileCategoryFilterShow = isShow
        }
    },

    actions: {

        changeMobileCategoryFilterShow({ commit, getters }) {
            commit('setMobileCategoryFilterShow', !getters.getMobileCategoryFilterShow)
        }

    }

}