import { Logger } from "@storefront/core/lib/logger";
import { getProductByCategoryId } from "@storefront/core/data-resolver/products";
import config from "@config";

const state = () => ({
  Bundles: [],
  NewInRangeProducts: [],
  InActionProducts: [],
  BestSellers: [],
  overlay: false,
});

const actions = {
  async loadBundles({ commit }) {
    const products = await getProductByCategoryId(config.bundleList, 1).catch(
      (e) => {
        Logger.error("loadBundles", "home.js", e)();
        throw e;
      }
    );
    commit("setBundleProducts", products.items);
  },
  async loadNewInRange({ commit }) {
    var currentPage = 1;
    var selectedProducts = [];
    var maxPages = 99;
    while (currentPage <= maxPages && selectedProducts.length < 12) {
      const products = await getProductByCategoryId(
        config.categories.new_in_range,
        currentPage
      ).catch((e) => {
        Logger.error("loadNewInRange", "home.js", e)();
        throw e;
      });
      products.items.forEach((p) => {
        if (p.stock_status == "IN_STOCK" && selectedProducts.length < 12) {
          selectedProducts.push(p);
        }
      });
      currentPage = currentPage + 1;
      maxPages = products.page_info.total_pages;
    }
    commit("setNewInRangeProducts", selectedProducts);
  },
  async loadInActionProducts({ commit }) {
    var currentPage = 1;
    var selectedProducts = [];
    var maxPages = 99;
    while (currentPage <= maxPages && selectedProducts.length < 12) {
      const products = await getProductByCategoryId(
        config.categories.in_action,
        currentPage
      ).catch((e) => {
        Logger.error("loadInActionProducts", "home.js", e)();
        throw e;
      });
      products.items.forEach((p) => {
        if (p.stock_status == "IN_STOCK" && selectedProducts.length < 12) {
          selectedProducts.push(p);
        }
      });
      currentPage = currentPage + 1;
      maxPages = products.page_info.total_pages;
    }
    commit("setInActionProducts", selectedProducts);
  },
  async loadBestSellers({ commit }) {
    var currentPage = 1;
    var selectedProducts = [];
    var maxPages = 99;
    while (currentPage <= maxPages && selectedProducts.length < 12) {
      const products = await getProductByCategoryId(
        config.categories.best_seller,
        currentPage
      ).catch((e) => {
        Logger.error("loadBestSellers", "home.js", e)();
        throw e;
      });
      products.items.forEach((p) => {
        if (p.stock_status == "IN_STOCK" && selectedProducts.length < 12) {
          selectedProducts.push(p);
        }
      });
      currentPage = currentPage + 1;
      maxPages = products.page_info.total_pages;
    }
    commit("setBestSellers", selectedProducts);
  },
};

const mutations = {
  setBundleProducts(state, payload) {
    state.Bundles = payload;
  },
  setNewInRangeProducts(state, payload) {
    state.NewInRangeProducts = payload;
  },
  setInActionProducts(state, payload) {
    state.InActionProducts = payload;
  },
  setBestSellers(state, payload) {
    state.BestSellers = payload;
  },
  updateOverlay(state) {
    state.overlay = !state.overlay;
  },
};

const getters = {
  getBundleProducts: (state) => state.Bundles,
  getNewInRangeProducts: (state) => state.NewInRangeProducts,
  getInActionProducts: (state) => state.InActionProducts,
  getBestSellers: (state) => state.BestSellers,
  getRandomBundles: (state) => (nr) => {
    const bundles = JSON.parse(JSON.stringify(state.Bundles));
    const shuffled = bundles.sort(() => 0.5 - Math.random());
    if (nr > bundles.length) {
      nr = bundles.length;
    }
    // Get sub-array of first n elements after shuffled
    return shuffled.slice(0, nr);
  },
  getOverlay: (state) => state.overlay,
};

// export this module.
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
