import { Logger } from "@storefront/core/lib/logger";
import { getProductDetails } from "@storefront/core/data-resolver/products";
import coreProduct from "@storefront/core/modules/catalog/store/product";
import config from "@config";
import store from "@/store";
import axios from "axios";
import { getCurrentLanguage, i18n } from "@storefront/core/i18n";

const state = () => ({
  ...coreProduct.state(),

  currentCustomMeasures: [],
});

const actions = {
  ...coreProduct.actions,

  async loadConfigProduct({ commit }, { sku, type }) {
    const products = await getProductDetails(sku, type).catch((e) => {
      Logger.error("getProductDetails", "product store actions load", e)();
      throw e;
    });
    const product = products.items[0];
    commit("setCurrentConfig", product);

    const prodOptions = [];
    Logger.debug(
      "conf options",
      "store",
      product.configurable_options.length
    )();

    if (product.configurable_options.length == 1) {
      Logger.debug(
        "conf options",
        "store",
        product.configurable_options.length
      )();
      const values = [];
      product.variants.forEach((element) => {
        const value = {
          label: element.attributes[0].label,
          value_index: element.product.sku,
          price: element.product.price_range.minimum_price.final_price.value.toFixed(
            2
          ),
        };
        values.push(value);
      });
      const prodOption = {
        index: 0,
        attribute_code: product.configurable_options[0].attribute_code,
        label: product.configurable_options[0].label,
        id: product.configurable_options[0].id,
        choice: null,
        values: values,
      };
      prodOptions.push(prodOption);

      if (product.configurable_options[0].attribute_code == "fkv_maten") {
        const cvalues = [];
        if (product.size_chart != null) {
          if (product.size_chart.sizes.length != null) {
            product.variants.forEach((element) => {
              const found = product.size_chart.sizes.find((v) => {
                Logger.debug(
                  "element.value_index",
                  "product store",
                  element.attributes[0].value_index
                )();
                Logger.debug(
                  "v.from.option_id",
                  "product store",
                  v.from.option_id
                )();
                if (v.from.option_id == element.attributes[0].value_index) {
                  return true;
                }
              });
              Logger.debug("found", "product store", found)();
              if (found != null) {
                const cvalue = {
                  label: found.to.label,
                  value_index: element.product.sku,
                };
                cvalues.push(cvalue);
              }
            });
          }
        }
        commit("setCurrentCustomMeasures", cvalues);
      }
    } else {
      product.configurable_options.forEach((option, index) => {
        if (index == 0) {
          const prodOption = {
            index: index,
            attribute_code: option.attribute_code,
            label: option.label,
            id: option.id,
            choice: null,
            values: option.values,
          };
          prodOptions.push(prodOption);
          if (option.attribute_code == "fkv_maten") {
            const cvalues = [];
            if (product.size_chart.sizes.length != null) {
              option.values.forEach((element) => {
                const found = product.size_chart.sizes.find((v) => {
                  Logger.debug(
                    "element.value_index",
                    "product store",
                    element.value_index
                  )();
                  Logger.debug(
                    "v.from.option_id",
                    "product store",
                    v.from.option_id
                  )();
                  if (v.from.option_id == element.value_index) {
                    return true;
                  }
                });
                Logger.debug("found", "product store", found)();
                if (found != null) {
                  const cvalue = {
                    label: found.to.label,
                    value_index: element.value_index,
                  };
                  cvalues.push(cvalue);
                }
              });
              commit("setCurrentCustomMeasures", cvalues);
            }
          }
        } else {
          const prodOption = {
            index: index,
            attribute_code: option.attribute_code,
            label: option.label,
            choice: null,
            id: option.id,
            values: [],
          };
          prodOptions.push(prodOption);
        }
      });
    }
    commit("setCurrentOptions", prodOptions);
    commit("setCurrentChildSku", null);
  },

  async addNotifyEmail(_, { email, sku }) {
    Logger.debug("addNotifyEmail", "email sku", { email, sku })();

    const lang = getCurrentLanguage();
    const storelang = config.languages[lang];
    const storeview = storelang["storeview"];
    let headers = {};
    if (store.getters["user/getIsLoggedIn"] != false) {
      headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + store.getters["user/getUserToken"],
        Store: storeview,
      };
    } else {
      headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + config.shop.consumerKey,
        Store: storeview,
      };
    }

    let query =
      'mutation { productAlertSubscription( input: { email: "' + email + '" ';
    query = query + 'sku:"' + sku + '"';
    query = query + "type:stock}";

    query = query + " ) { email sku type } }";

    const retval = await axios({
      url: config.shop.graphQLURL,
      method: "POST",
      headers: headers,
      data: { query: query },
    }).catch((e) => {
      Logger.error("addNotifyEmail", "product store", e)();
      throw e;
    });

    Logger.debug("addNotifyEmail", "retval", retval)();

    if (retval.data.data.productAlertSubscription != null) {
      return true;
    } else {
      if (retval.data.errors != null) {
        retval.data.errors.forEach((element) => {
          const msg = {
            type: "danger",
            title: i18n.t("Add to cart"),
            text: element.message,
          };
          store.dispatch("messages/sendMessage", { message: msg });
        });
      }
      return false;
    }
  },
};

const mutations = {
  ...coreProduct.mutations,

  setCurrentCustomMeasures(state, data) {
    state.currentCustomMeasures = data;
  },
};

const getters = {
  ...coreProduct.getters,

  getCurrentCustomMeasures: (state) => state.currentCustomMeasures,
};

// export this module.
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
